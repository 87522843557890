import React, { useContext } from "react";
import track from "../../tracking/analytics";
import PropTypes from "prop-types";
import { trackGa } from "../../tracking/googleAnalytics";
import { MediaAuthContext } from "../../store/context/media-auth-context";
import { LayoutContext } from "../../store/context/layout-context";
import { POPUP_TYPES } from "../../helpers/constants";
// import { Link } from "gatsby";

const TrackedLink = (props) => {
  const {
    handleClick,
    redash = true,
    // isLink = false,
    isChild = false,
    category,
    action,
    label,
    classes,
    path,
    target,
    title,
    children,
    rel,
    showMobileProPopup,
    class: customClass,
  } = props;
  const currentLabel = label ? label : path;
  const currentClass = customClass || classes;
  const currentTarget = target ? "_blank" : "_self";
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);
  const { popupState, openPopup } = useContext(LayoutContext);
  const initTrack = (e) => {
    e.preventDefault();
    handleClick && handleClick();
    if (showMobileProPopup && isNotInitMobile && !popupState.open) {
      openPopup(POPUP_TYPES.MOBILE_PRO);
    } else {
      redash && track(category, action, currentLabel);
      trackGa(action, {
        category: category,
        label: currentLabel,
        transport_type: "beacon",
      });
      if (isChild) {
        window.parent.location.href = path;
        return;
      }
      target
        ? window.open(path, "_blank").focus()
        : window.open((window.location.href = path), "_self");
    }
  };
  const linkProps = {
    className: currentClass,
    href: path,
    target: currentTarget,
    onClick: initTrack,
  };
  if (rel) {
    linkProps.rel = rel;
  }

  return <a {...linkProps}>{title || children}</a>;

  // return path === "/" || !isLink ? (
  //   <a {...linkProps} href={path}>
  //     {title || children}
  //   </a>
  // ) : (
  //   <Link {...linkProps} to={path}>
  //     {title || children}
  //   </Link>
  // );
};

TrackedLink.propTypes = {
  category: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  classes: PropTypes.string,
  path: PropTypes.string.isRequired,
  target: PropTypes.bool,
  redash: PropTypes.bool,
  // isLink: PropTypes.bool,
  isChild: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default TrackedLink;
