export default [
  {
    path: "/bundles/webapp-and-extensionpro-tool-kit",
    to: "/checkout/?bundle=amazonsellersbundlewcs",
  },
  {
    path: "/checkout/?bundle=sellersbundle189",
    to: "/checkout/?bundle=sellersbundle189wcs",
  },
  {
    path: "/checkout/?bundle=p199allinoneamazonstarterbundle",
    to: "/checkout/?bundle=p199allinoneamazonstarterbundlewcs",
  },
  {
    path: "/checkout/?bundle=196amazonprotools",
    to: "/checkout/?bundle=196amazonprotoolswcs",
  },
  {
    path: "/checkout/?bundle=195allinoneamazonstarterbundle",
    to: "/checkout/?bundle=195allinoneamazonstarterbundlewcs",
  },
  {
    path: "/checkout/?bundle=amazonsellersbundlepartner",
    to: "/checkout/?bundle=amazonsellersbundlepartnerwcs",
  },
  {
    path: "/checkout/?bundle=amazonsellersbundle",
    to: "/checkout/?bundle=amazonsellersbundlewcs",
  },
  {
    path: "/checkout/?bundle=amazonsellersbundlepartnersmonth",
    to: "/checkout/?bundle=amazonsellersbundlepartnersmonthwcs",
  },
  {
    path: "/checkout/?bundle=amazonsellersbundlemonth",
    to: "/checkout/?bundle=amazonsellersbundlemonthwcs",
  },
];
