import AvailabilityModal from "../modals/availabilityModal";

export default class AvailabilityController extends AvailabilityModal {
  constructor() {
    super();
    this.availabilityTime = localStorage.getItem("availability");
    this.availabilityStatus = localStorage.getItem("availability-status");
  }

  init() {
    this.localCheck();
  }

  handleRequest() {
    this.localClear();
    this.request()
      .then(() => {
        this.writeLocal(false);
      })
      .catch(() => {
        this.writeLocal(true);
        this.goToBlock();
      });
  }

  dateDiff(firstDate, secondDate) {
    const getDate = (string) =>
      new Date(0, 0, 0, string.split(":")[0], string.split(":")[1]);
    const different = getDate(secondDate) - getDate(firstDate);
    return Math.floor((different % 86400000) / 3600000); // hours
  }

  localCheck() {
    if (this.availabilityTime) {
      const now = this.getDate(new Date());
      const past = this.getDate(this.availabilityTime);
      const diff = this.dateDiff(past, now);
      if (diff >= 1 || diff < 0) {
        this.handleRequest();
      } else {
        if (this.availabilityStatus === "true") {
          this.goToBlock();
        }
      }
    } else {
      this.handleRequest();
    }
  }

  localClear() {
    if (this.availabilityTime) {
      localStorage.removeItem("availability");
    }
  }

  writeLocal(status) {
    localStorage.setItem("availability", new Date().toString());
    localStorage.setItem("availability-status", status);
  }

  getDate(time) {
    const date = new Date(time);
    return date.getHours() + ":" + date.getMinutes();
  }

  goToBlock() {
    const path = window.location.pathname;
    const termsPage = path.includes("terms-of-use");
    if (!path.includes("why-not-available") && !termsPage) {
      window.location.replace(window.origin + "/why-not-available");
    }
  }
}
