export const updateContent = (t) => ({
  common: [
    {
      value: "option1",
      text: t("SelectView_FirstOption"),
    },
    {
      value: "option2",
      text: t("SelectView_SecondOption"),
    },
    {
      value: "option3",
      text: t("SelectView_ThirdOption"),
    },
    {
      value: "option4",
      text: t("SelectView_FourthOption"),
    },
  ],
  paid: [
    {
      value: "option1",
      text: t("SelectView_FirstOption"),
    },
    {
      value: "option2",
      text: t("SelectView_SecondOption"),
    },
    {
      value: "option4",
      text: t("SelectView_FourthOption"),
    },
  ],
  newpro: [
    {
      value: "option1",
      text: t("SelectView_SecondOption"),
    },
    {
      value: "option2",
      text: t("SelectView_FourthOption"),
    },
  ],
});
