import React, { useState, useEffect, useCallback, useContext } from "react";
import { LayoutContext } from "../../store/context/layout-context";
import Help from "../../components/common/Help";
import { log } from "../../helpers";

const helpContainerContent = {
  scriptChunks: [
    '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});',
    'window.Beacon("init", "f48d2025-7e23-4511-857d-9ff6624aa4d1")',
  ],
};

const HelpContainer = (props) => {
  const { changeSurveyButton, topPanel, topPanelDR, topPanelBundle } = props;
  const { helpState, updateHelpState } = useContext(LayoutContext);
  const [state, setState] = useState({
    load: false,
    disabled: false,
    display: false,
    countOfLoading: 0,
    maxCountOfLoading: 20,
    timerId: null,
    topPanel,
    topPanelDR,
    topPanelBundle,
  });

  const updateState = useCallback((key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const handleInProgress = useCallback(
    (status) => {
      updateState("disabled", status);
      changeSurveyButton && changeSurveyButton();
    },
    [changeSurveyButton, updateState]
  );

  const handleInjection = useCallback(() => {
    helpContainerContent.scriptChunks.forEach((chunk) => {
      const script = document.createElement("script");
      script.innerHTML = chunk;
      document.body.appendChild(script);
      updateState("load", true);
    });
  }, [updateState]);

  const handleChatDisplay = useCallback(() => {
    handleInProgress(true);
    if (!state.load) {
      handleInjection();
    }
    handleLoad();
  }, [handleInProgress, handleInjection, state.load]);

  const handleLoad = useCallback(() => {
    if (state.countOfLoading >= state.maxCountOfLoading) {
      handleInProgress(false);
    } else if (window.Beacon === undefined) {
      reload();
    } else {
      hideHelpWithDelay();
      try {
        // eslint-disable-next-line new-cap
        window.Beacon("open");
        webWidgetPositionCheck(topPanel || topPanelDR || topPanelBundle);
      } catch (err) {
        log(err, "Help Error", true);
        reload(1000);
      }
    }
    setState((prevState) => ({
      ...prevState,
      countOfLoading: prevState.countOfLoading + 1,
    }));
  }, [
    handleInProgress,
    topPanel,
    topPanelDR,
    topPanelBundle,
    state.countOfLoading,
    state.maxCountOfLoading,
    updateState,
  ]);

  const reload = useCallback(
    (time = 1000) => {
      if (state.timerId) clearTimeout(state.timerId);
      const timerId = setTimeout(() => {
        handleLoad();
      }, time);
      updateState("timerId", timerId);
    },
    [state.timerId, updateState, handleLoad]
  );

  const hideHelpWithDelay = useCallback(() => {
    const timerId = setTimeout(() => {
      updateState("display", false);
      updateState("disabled", false);
      clearTimeout(timerId);
    }, 300);
  }, [updateState]);

  const webWidgetPositionCheck = useCallback(
    (status) => {
      [
        document.querySelector(".BeaconContainer"),
        document.querySelector(".BeaconFabButtonFrame"),
      ].forEach((item) => {
        if (item) {
          item.style.marginBottom = status
            ? topPanelBundle
              ? "60px"
              : "100px"
            : "0";
        } else {
          throw new Error("Undefined chat item");
        }
      });
    },
    [topPanelBundle]
  );

  useEffect(() => {
    if (!window.Beacon) {
      updateState("display", true);
    }
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("help") === "true" || helpState?.isFirstClick) {
        handleChatDisplay();
      }
    }
    return () => {
      clearTimeout(state.timerId);
    };
  }, [updateState, handleChatDisplay, helpState?.isFirstClick]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (helpState?.isClick) {
        window.Beacon("open");
        updateHelpState && updateHelpState({ isClick: false });
      }
    }
  }, [helpState?.isClick]);

  return state.display ? (
    <Help
      disabled={state.disabled}
      buttonClick={handleChatDisplay}
      topPanel={topPanel}
      topPanelDR={topPanelDR}
      topPanelBundle={topPanelBundle}
    />
  ) : null;
};

export default HelpContainer;
