import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Container = ({ children, navSize, webSize, mediumSize, modeClass }) => {
  return (
    <div
      className={getTrueClasses(
        "Container",
        navSize && "Container_big",
        webSize && "Container_web",
        mediumSize && "Container_medium",
        modeClass && modeClass
      )}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  navSize: PropTypes.bool,
  mediumSize: PropTypes.bool,
  modeClass: PropTypes.string,
};

export default Container;
