const COOKIE_CONTENT = {
  en: {
    title: "We use cookies to make your experience better.",
    textLink: " More here."
  },
  ja: {
    title: "お客様の当サイトのご利用を改善していただくために、クッキーを利用しています。",
    textLink: " 詳細はこちら"
  },
  cn: {
    title: "我们使用cookies 是为了增加您的用户体验。",
    textLink: " 更多信息。"
  },
  de: {
    title: "Wir verwenden Cookies, um Ihr Erlebnis zu verbessern.",
    textLink: " Mehr dazu hier."
  }
};

export default COOKIE_CONTENT;