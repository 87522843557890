import AvailabilityController from "./controllers/availabilityController";
import MainController from "./controllers/mainController";

export default function () {
  const availabilityController = new AvailabilityController();
  const mainController = new MainController();

  availabilityController.init();
  mainController.init();
}
