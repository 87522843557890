import React, { useContext } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";

const FeedbackView = ({ textField, onChangeHandler, sendFeedback }) => {
  const context = useContext(PageContext);
  const t = context.translate;

  return (
    <section className="feedbackView">
      <p className="feedbackView__text">
        {`${t("FeedbackView_Title")} `}
        <br />
        {t("FeedbackView_Title_SecondChunk")}
      </p>
      <textarea
        className="feedbackView__field"
        cols="50"
        rows="5"
        value={textField}
        onChange={onChangeHandler}
        placeholder={t("FeedbackView_Placeholder")}
      />
      <div
        className={`feedbackView__btn ${
          textField ? "" : "feedbackView__btn_disabled"
        }`}
        onClick={sendFeedback}
      >
        {t("FeedbackView_Button")}
      </div>
    </section>
  );
};
FeedbackView.propTypes = {
  textField: PropTypes.string,
  onChangeHandler: PropTypes.func,
  sendFeedback: PropTypes.func,
};
export default FeedbackView;
