import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import COOKIE_CONTENT from "./index.content";
import CloseIcon from "../../../assets/images/vector/components/Cookie/cookieCros.svg";
import "./index.scss";

const Cookie = (props) => {
  const {
    close,
    langCode = "en",
    topPanel,
    topPanelBundle,
    topPanelDR,
    underForm,
  } = props;

  const { title, textLink } = COOKIE_CONTENT[langCode];

  const classes = getTrueClasses(
    "Cookie",
    topPanelDR && "Cookie_topPanelDR",
    topPanelBundle && "Cookie_topPanelBundle",
    topPanel && "Cookie_topPanel",
    underForm && "Cookie_top",
    langCode !== "en" && `Cookie_${langCode}`
  );

  return (
    <div className={classes}>
      <p>
        {title}
        <a href="/privacy-policy/" onClick={close}>
          {textLink}
        </a>
      </p>
      <img src={CloseIcon} alt="Close" onClick={close} />
    </div>
  );
};
Cookie.propTypes = {
  close: PropTypes.func.isRequired,
  langCode: PropTypes.string,
  topPanel: PropTypes.bool,
  topPanelBundle: PropTypes.bool,
  topPanelDR: PropTypes.bool,
  underForm: PropTypes.bool,
};

export default Cookie;
