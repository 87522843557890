import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";
import "./index.scss";

const SelectView = ({
  getSelectState,
  handleSelectChange,
  selectFormContent,
}) => {
  const { translate: t } = useContext(PageContext);

  return (
    <section className="selectView">
      <p className="selectView__title selectView__title_sm">
        {t("SelectView_FirstTitle")}
      </p>
      <p className="selectView__title selectView__title_bg">
        {t("SelectView_SecondTitle")}
      </p>

      <>
        {selectFormContent.map((content) => {
          return (
            <label
              key={content.value}
              className={
                getSelectState === content.value
                  ? "radioContainer radioContainer_active"
                  : "radioContainer"
              }
            >
              {content.text}
              <input
                onChange={handleSelectChange}
                type="radio"
                value={content.value}
                checked={getSelectState === content.value}
              />
              <span className="checkmark" />
            </label>
          );
        })}
      </>
    </section>
  );
};

SelectView.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  getSelectState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  selectFormContent: PropTypes.array,
};

export default SelectView;
