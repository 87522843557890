import React, { useContext, useState } from "react";
import FeedbackView from "../../components/ext-questionnaire/Feedback";
import { PageContext } from "../../store/context/page-context";
import PropTypes from "prop-types";

const FeedbackViewContainer = ({ software }) => {
  const context = useContext(PageContext);
  const isCn = context.isCn;
  const params = new URL(window.location.href).searchParams;
  const email = params.get("email") || "-";

  const [textField, setTextField] = useState("");

  const onChangeHandler = (e) => setTextField(e.target.value);
  const redirectPath = isCn
    ? process.env.MAIN_HOST + "/cn/"
    : process.env.MAIN_HOST;
  const path = `/review/uninstall?email=${encodeURIComponent(email)}${
    isCn ? "&locale=zh" : ""
  }&software=${encodeURIComponent(software)}`;

  const sendFeedback = () => {
    if (textField) {
      const headers = {
        Accept: "application/json",
        "Content-type": "text/plain",
      };
      fetch(process.env.API_HOST + path, {
        headers,
        method: "POST",
        body: textField,
      }).finally(() => window.open(redirectPath, "_self"));
    }
  };

  return (
    <FeedbackView
      textField={textField}
      onChangeHandler={onChangeHandler}
      sendFeedback={sendFeedback}
    />
  );
};

FeedbackViewContainer.propTypes = {
  software: PropTypes.string.isRequired,
};

export default FeedbackViewContainer;
