import React, { useState, useEffect } from "react";
import { LayoutContext } from "../../store/context/layout-context";
import useFingerPrint from "../../hooks/useFingerPrint";
import PropTypes from "prop-types";
import Cookie from "../../components/common/Cookie";
import HelpContainer from "../../containers/common/HelpContainer";
import redirectLogic from "../../helpers/redirect";
import TagManager from "react-gtm-module";
import "../../assets/scss/fonts/fonts.scss";
import "../../assets/scss/index.scss";

const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};

const LayoutEmpty = (props) => {
  const {
    className,
    children,
    disableCookie,
    disableTagManager,
    disableHelpBlock,
    hideCookiePanel,
  } = props;
  const [isShowCookie, setIsShowCookie] = useState(false);
  const [helpState, setHelpState] = useState({
    isFirstClick: false,
    isClick: false,
  });

  useEffect(() => {
    !disableCookie && TagManager.initialize(tagManagerArgs);
    !disableTagManager && checkDisclamer();
    redirectLogic();
  }, []);

  useFingerPrint(false);

  const updateHelpState = (args) => {
    setHelpState((prevState) => ({
      ...prevState,
      ...args,
    }));
  };

  const handlerClickHelp = () => {
    if (helpState.isFirstClick) {
      updateHelpState({ isClick: true });
    } else {
      updateHelpState({ isFirstClick: true });
    }
  }

  const checkDisclamer = () => {
    const isDisclamer = localStorage.getItem("disclamer");
    setIsShowCookie(!isDisclamer);
  };

  const closeCookieAlert = () => {
    localStorage.setItem("disclamer", true);
    setIsShowCookie(false);
  };

  return (
    <LayoutContext.Provider
      value={{
        helpState,
        updateHelpState,
        handlerClickHelp,
      }}
    >
    <>
      {!hideCookiePanel && isShowCookie && <Cookie close={closeCookieAlert} />}
      {!disableHelpBlock && <HelpContainer />}
      <main className={className}>{children}</main>
    </>
    </LayoutContext.Provider>
  );
};

LayoutEmpty.protoTypes = {
  children: PropTypes.node.isRequired,
  class: PropTypes.string,
  disableCookie: PropTypes.bool,
  disableTagManager: PropTypes.bool,
  disableHelpBlock: PropTypes.bool,
  hideCookiePanel: PropTypes.bool,
};

export default LayoutEmpty;
