import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Help = (props) => {
  const { disabled, buttonClick, topPanel, topPanelDR, topPanelBundle } = props;
  const classes = getTrueClasses(
    "Help",
    disabled && "disabled",
    topPanelDR && "Help_topPanelDR",
    topPanel && "Help_topPanel",
    topPanelBundle && "Help_topPanelBundle"
  );

  return (
    <button className={classes} onClick={buttonClick}>
      <span />
      <span>Help</span>
    </button>
  );
};

Help.propTypes = {
  disabled: PropTypes.bool,
  buttonClick: PropTypes.func.isRequired,
  topPanelBundle: PropTypes.bool,
};

export default Help;
