export default class AvailabilityModal {
  request() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url = `${process.env.AUTH_HOST}/v1/auth/check`;
      xhr.open("GET", url, true);
      xhr.onreadystatechange = function () {
        if (xhr.status === 403) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }
}
