import { getLocation } from "../../index";
import commonRules from "../rules/commonRules";

export default class MainController {
  constructor() {
    this.userLang = navigator.language || navigator.userLanguage;
    this.waLang = localStorage.getItem("langName");
    this.cnIp = false;
    this.deIp = false;
    this.jpIp = false;
    this.cnLang = false;
    this.deLang = false;
    this.jpLang = false;
    this.enSelect = localStorage.getItem("enSelect");
    this.tempLangSelect = sessionStorage.getItem("langSelect");
  }

  init() {
    getLocation()
      .then((res) => {
        window.countryCode = res.data.countryCode;
        this.cnIp = res.data.countryCode === "CN";
        this.deIp = res.data.countryCode === "DE";
        this.jpIp = res.data.countryCode === "JP";
        this.cnLang = this.userLang.includes("zh");
        this.deLang = this.userLang.includes("de");
        this.jpLang = this.userLang.includes("ja");
      })
      .catch(() => {
        window.countryCode = "US";
      })
      .finally(() => {
        this.cnDetect = this.cnLang || this.cnIp;
        this.deDetect = this.deLang || this.deIp;
        this.jpDetect = this.jpLang || this.jpIp;
        this.runStrictRedirects();
        this.runSimpleRedirects(commonRules);
      });
  }

  checkChanceRedirect(lang) {
    if (!localStorage.getItem(`${lang}FirstVisit`)) {
      this.mainRedirect(lang);
    }
  }

  mainRedirect(lang) {
    if (window.location.pathname === "/") {
      window.location.replace(window.origin + `/${lang}`);
      localStorage.setItem(`${lang}FirstVisit`, true);
    }
  }

  pageRedirect(path, cnPath, referrer, exception) {
    const check = exception
      ? document.referrer.includes(exception)
      : !document.referrer.includes(referrer);
    if (
      (window.location.pathname === path ||
        window.location.pathname === path + "/") &&
      check
    ) {
      window.location.pathname = cnPath;
    }
  }

  runStrictRedirects() {
    if (this.waLang === "zh" || this.cnDetect) {
      this.pageRedirect(
        "/extension/pricing",
        "/extension/cn/pricing/",
        "/extension/pricing"
      );
      this.pageRedirect(
        "/iframe/customizable",
        "/cn/iframe/customizable/",
        "/iframe/customizable"
      );
      // this.pageRedirect(
      //   "/extension/upgrade",
      //   "/cn/iframe/customizable/",
      //   "/iframe/customizable"
      // );
      // this.pageRedirect(
      //   "/upgrade",
      //   "/cn/upgrade/",
      //   "/upgrade",
      //   "/extension/cn/upgrade"
      // );
      this.pageRedirect(
        "/authorization",
        "/zh/authorization/",
        "/authorization"
      );
      this.pageRedirect("/extpro", "/zh/extpro/", "/extpro");
      this.pageRedirect("/extoa", "/zh/extoa/", "/extoa");
      this.pageRedirect("/extqv", "/zh/extqv/", "/extqv");
    }
    if (this.waLang === "ja" || this.jpDetect) {
      this.pageRedirect(
        "/iframe/customizable",
        "/ja/iframe/customizable/",
        "/iframe/customizable"
      );
      // this.pageRedirect(
      //   "/extension/upgrade",
      //   "/ja/iframe/customizable/",
      //   "/iframe/customizable"
      // );
    }
    if (this.waLang === "en") {
      this.pageRedirect(
        "/extension/cn/pricing",
        "/extension/pricing/",
        "/extension/pricing"
      );
      this.pageRedirect(
        "/cn/iframe/customizable",
        "/iframe/customizable/",
        "/iframe/customizable"
      );
      this.pageRedirect(
        "/ja/iframe/customizable",
        "/iframe/customizable/",
        "/iframe/customizable"
      );
      this.pageRedirect(
        "/de/iframe/customizable",
        "/iframe/customizable/",
        "/iframe/customizable"
      );
    }

    if (this.cnLang || this.cnDetect) {
      this.checkChanceRedirect("cn");
      this.pageRedirect("/checkout", "/cn/checkout/", "/checkout");
      this.pageRedirect(
        "/iframe/seller-kit",
        "/zh/iframe/seller-kit",
        "/iframe/seller-kit"
      );
      this.pageRedirect("/seller-kit", "/zh/seller-kit", "/seller-kit");
      this.pageRedirect(
        "/seller-kit-reselling",
        "/zh/seller-kit-reselling",
        "/seller-kit-reselling"
      );
    }

    if (this.cnDetect && !this.tempLangSelect) {
      this.pageRedirect(
        "/iframe/seller-kit",
        "/zh/iframe/seller-kit",
        "/iframe/seller-kit"
      );
      this.pageRedirect("/seller-kit", "/zh/seller-kit", "/seller-kit");
      this.pageRedirect(
        "/seller-kit-reselling",
        "/zh/seller-kit-reselling",
        "/seller-kit-reselling"
      );
    }

    if (this.deDetect && !this.tempLangSelect) {
      this.pageRedirect(
        "/iframe/seller-kit",
        "/de/iframe/seller-kit",
        "/iframe/seller-kit"
      );
      this.pageRedirect("/seller-kit", "/de/seller-kit", "/seller-kit");
      this.pageRedirect(
        "/seller-kit-reselling",
        "/de/seller-kit-reselling",
        "/seller-kit-reselling"
      );
      this.pageRedirect(
        "/iframe/customizable",
        "/de/iframe/customizable/",
        "/iframe/customizable"
      );
    }
    if (this.deLang || this.deDetect) {
      this.checkChanceRedirect("de");
    }

    if ((this.cnLang || this.cnDetect) && !this.enSelect) {
      this.pageRedirect("/pro-ext/pricing", "/cn/pricing/", "/pricing");
      this.pageRedirect("/pricing", "/cn/pricing/", "/pricing");
    }
    if ((this.jpLang || this.jpDetect) && !this.enSelect) {
      this.pageRedirect("/pricing", "/ja/pricing/", "/pricing");
    }
    if ((this.deLang || this.deDetect) && !this.enSelect) {
      this.pageRedirect("/pricing", "/de/pricing/", "/pricing");
    }
  }

  runSimpleRedirects(pages) {
    pages.forEach((obj) => {
      if (window.location.href === `${window.location.origin}${obj.path}`) {
        return window.location.replace(obj.to);
      }
    });
  }
}
